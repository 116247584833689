import React from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import Footer from "../components/Footer"
import Layout from "../components/Layout"
import Meta from "../components/Meta"
import Navbar from "../components/Navbar"
import { PageProps } from "../helpers/props"
import { cookies } from "../helpers/cookies"

const StyledCookiePolicy = styled.div`
  p + h2 {
    margin-top: 2rem;
  }

  p {
    margin-bottom: 0.5rem;

    &:last-child {
      font-style: italic;
      margin-top: 1.5rem;
    }
  }

  table:not(:last-child) {
    margin-bottom: 2rem;
  }

  ul {
    margin-left: 1.1rem;
    list-style-type: circle;
  }
`

const CookiePolicyPage: React.FunctionComponent<PageProps> = ({ location }) => {
  const intl = useIntl()
  const title = intl.formatMessage({ id: "Cookie Policy" })

  function getCategoryUsedFor(categoryId: string): string {
    switch (categoryId) {
      case "statistics":
        return "Measure traffic and analyze your behavior with the goal of improving our service."
      case "marketing":
        return "Deliver personalized marketing content to you based on your behavior and to operate, serve and track ads."
      default:
        return "Allowing our service working and, therefore, do not require you to consent."
    }
  }

  function listCookies(): string[][] {
    return Object.keys(cookies).reduce((acc: string[][], categoryId: string) => {
      const tempArray = Object.keys(cookies[categoryId]).reduce((accc: string[][], productId: string) => {
        const product = cookies[categoryId][productId]
        if (!product.showInPolicy) return accc
        return [...accc, ...product.cookies.map(cookie => [categoryId, cookie, product.name, product.expiresAfter])]
      }, [])
      return [...acc, ...tempArray]
    }, [])
  }

  return (
    <Layout>
      <Meta pathname={location.pathname} title={title} />
      <Navbar />
      <section className="section">
        <div className="container">
          <h1 className="title is-1 has-text-centered">{title}</h1>
          <StyledCookiePolicy>
            <h2 className="title is-2">What are cookies?</h2>
            <p>
              This Cookie Policy explains what cookies are and how we use them, the types of cookies we use i.e, the
              information we collect using cookies and how that information is used, and how to manage the cookie
              settings.
            </p>
            <p>
              Cookies are small text files that are used to store small pieces of information. They are stored on your
              device when the website is loaded on your browser. These cookies help us make the website function
              properly, make it more secure, provide better user experience, and understand how the website performs and
              to analyze what works and where it needs improvement.
            </p>
            <h2 className="title is-2">How do we use cookies?</h2>
            <p>
              As most of the online services, our website uses first-party and third-party cookies for several purposes.
              First-party cookies are mostly necessary for the website to function the right way, and they do not
              collect any of your personally identifiable data.
            </p>
            <p>
              The third-party cookies used on our website are mainly for understanding how the website performs, how you
              interact with our website, keeping our services secure, providing advertisements that are relevant to you,
              and all in all providing you with a better and improved user experience and help speed up your future
              interactions with our website.
            </p>
            <h2 className="title is-2">Types of cookies we use</h2>
            <table className="table is-striped">
              <thead>
                <tr>
                  <th>Cookie</th>
                  <th>Product</th>
                  <th>Used for</th>
                  <th>Expires in</th>
                </tr>
              </thead>
              <tbody>
                {listCookies().map((columns, idx) => (
                  <tr key={idx}>
                    <td>{columns[1]}</td>
                    <td>{columns[2]}</td>
                    <td>{getCategoryUsedFor(columns[0])}</td>
                    <td>{columns[3]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <h2 className="title is-2">Manage cookie preferences</h2>
            <p>
              You can change your cookie preferences any time by clicking the link &quot;Cookie Policy&quot; in footer.
              This will let you revisit the cookie consent banner and change your preferences or withdraw your consent
              right away.
            </p>
            <p>
              In addition to this, different browsers provide different methods to block and delete cookies used by
              websites. You can change the settings of your browser to block/delete the cookies. Listed below are the
              links to the support documents on how to manage and delete cookies from the major web browsers; If you are
              using any other web browser, please visit your browser’s official support documents.
            </p>
            <div>
              <ul>
                <li>
                  <a
                    href="https://support.google.com/accounts/answer/32050"
                    rel="noreferrer"
                    target="_blank"
                    title="Chrome"
                  >
                    Chrome
                  </a>
                </li>
                <li>
                  <a
                    href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox"
                    rel="noreferrer"
                    target="_blank"
                    title="Firefox"
                  >
                    Firefox
                  </a>
                </li>
                <li>
                  <a
                    href="https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09"
                    rel="noreferrer"
                    target="_blank"
                    title="Microsoft Edge"
                  >
                    Microsoft Edge
                  </a>
                </li>
                <li>
                  <a
                    href="https://support.apple.com/en-in/guide/safari/sfri11471/mac"
                    rel="noreferrer"
                    target="_blank"
                    title="Safari"
                  >
                    Safari
                  </a>
                </li>
              </ul>
            </div>
            <p>Last updated January 17, 2023.</p>
          </StyledCookiePolicy>
        </div>
      </section>
      <Footer />
    </Layout>
  )
}

export default CookiePolicyPage
